import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const walletApi = createApi({
    reducerPath: "walletApi",
    baseQuery: defaultBaseQuery,
    tagTypes: ["Wallet"], // 定義 tag
    endpoints: (builder) => ({
        // Get wallet balance
        getWalletBalance: builder.query({
            query: () => ({
                url: `/BepWallet/GetWalletBalane`,
                method: "GET",
            }),
            providesTags: ["Wallet"],
        }),

        // Create wallet
        createWallet: builder.mutation({
            query: () => ({
                url: `/BepWallet/CreateWallet`,
                method: "POST",
            }),
            invalidatesTags: ["Wallet"],
        }),

        // Check has wallet or not
        checkHasWallet: builder.query({
            query: () => ({
                url: `/BepWallet/CkcekBepWallet`,
                method: "GET",
            }),
            providesTags: ["Wallet"],
            keepUnusedDataFor: 0,
        }),

        // Transfer USDT
        transferUSDT: builder.mutation({
            query: (body) => ({
                url: `/BepWallet/TransferUSDT`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["Wallet"],
        }),

        // get withdraw history
        withdrawTransactionRecord: builder.query({
            query: () => ({
                url: `/BepWallet/WithdrawTransactionRecord`,
                method: "GET",
            }),
        }),

        // get deposit history
        depositTransactionRecord: builder.query({
            query: () => ({
                url: `/BepWallet/DepositTransactionRecord`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetWalletBalanceQuery,
    useCreateWalletMutation,
    useCheckHasWalletQuery,
    useTransferUSDTMutation,
    useWithdrawTransactionRecordQuery,
    useDepositTransactionRecordQuery,
} = walletApi;
